@import '../abstracts/variables.scss';
@import '../abstracts/Animations.scss';

.zone {
	background: $background-color;

	.zone-actions-configurator {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;

		.actions-configurator-option {
			box-shadow: 0em 0em 0.3em 0.1em $shadow-sidebar-color;
			cursor: pointer;
			margin: 5px;
			padding: 10px;
			border-radius: 5px;

			display: flex;
			align-items: center;

			transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

			&:hover {
				color: #fff;
				fill: #fff;
				background-color: $color-blue;
				box-shadow: 0em 0em 0.3em 0.1em $shadow-color;
			}
		}
	}

	.zone-title {
		font-size: xx-large;
		font-weight: 500;
		text-align: left;
		outline: none;
		border: none;
		width: 100%;
	}
	.zone-content {
		display: flex;
		position: relative;
		width: 100%;
		padding: 0.5rem;
		margin-top: 0.5rem;
		border-radius: 0.5rem;

		@media (min-width: $breakpoint-tablet) {
			padding: 1rem;
		}

		.zone-products-list {
			width: 100%;

			.zone-add-product {
				width: 3rem;
				height: 3rem;
				padding: 0.25rem;
				cursor: pointer;

				transition: fill 250ms ease-in-out;

				&:hover {
					fill: $color-blue;
				}
			}

			.zone-product-footer {
				font-weight: 600;
				margin: 0.5rem 0 0.5rem 0;
				padding: 0 0.25rem;

				display: flex;
				flex-wrap: wrap;
			}

			.zone-product,
			.zone-product-header {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				align-content: center;
				align-items: center;

				position: relative;
				padding: 0.25rem 0.25rem 0.25rem 0.3rem;
				outline: none;
				margin-bottom: 2rem;

				border-bottom: dotted 1px black;

				@media (min-width: $breakpoint-tablet) {
					flex-direction: row;
					border-bottom: none;
				}

				@media (min-width: $breakpoint-laptop) {
					display: flex;
					position: relative;
					padding: 0.25rem;
					outline: none;
					margin-bottom: 0;
				}

				&:hover {
					.zone-product-actions {
						animation-name: show-opacity;
						animation-duration: 300ms;
						animation-delay: 150ms;
					}
				}

				.zone-product-actions {
					position: absolute;
					z-index: 500;
					width: 33px;
					height: 33px;
					right: 0;

					animation-name: show-opacity;

					@media (min-width: $breakpoint-laptop) {
						animation-name: hide-opacity;
					}

					animation-duration: 250ms;
					animation-delay: 100ms;
					animation-fill-mode: both;
					animation-timing-function: ease-in-out;

					.zone-product-actions-icon {
						position: relative;
						z-index: 500;
						left: 90%;

						padding: 4.5px;

						svg {
							width: 24px;
							height: 24px;
						}

						user-select: none;
					}

					.zone-product-actions-container {
						position: absolute;
						z-index: 250;
						top: -5px;
						right: -30px;
						// right: -100%;
						padding-right: 35px;
						padding-left: 2px;

						animation-name: close-option-drawer;
						animation-duration: 450ms;
						animation-delay: 0ms;
						animation-fill-mode: both;
						animation-timing-function: ease-in-out;

						.zone-product-actions-list {
							position: relative;
							display: flex;

							background-color: $background-color;
							border: $border-color 3px solid;
							border-radius: 18px;

							.zone-product-action {
								position: relative;
								height: 90%;

								background-color: $background-color;
								border-radius: 100%;
								// border: $border-color 3px solid;
								padding: 2px;

								margin: 0 4px;
								// visibility: hidden;

								animation-name: hide-opacity;
								animation-duration: 250ms;
								animation-delay: 100ms;
								animation-fill-mode: both;
								animation-timing-function: ease-in-out;
								transform-origin: right;

								// .zone-product-delete-icon {
								// 	position: relative;
								// 	// left: 90%;
								// }
							}
						}
					}

					&.open {
						.zone-product-actions-container {
							animation-name: open-option-drawer;
							animation-duration: 250ms;
							.zone-product-actions-list {
								.zone-product-action {
									animation-name: show-opacity;
									animation-delay: 300ms;
								}
							}
						}
					}

					// &:hover {

					// }
				}

				.zone-product-drag {
					position: absolute;
					left: -10px;
					width: 20px;
				}

				.zone-product-alert {
					position: absolute;
					left: -25px;
					top: -15px;
					// width: 20px;
				}
			}

			.zone-product-header {
				font-weight: 600;
				margin: 1rem 0 0.75rem 0;

				@media (max-width: $breakpoint-laptop) {
					display: none;
				}
			}

			.zone-product-input {
				position: relative;
				padding: 0.3rem;
				text-align: left;
				outline: none;
				border: none;
				border-radius: 2px;
				width: 100%;
				height: 30px;
				resize: none;
				box-shadow: 0 0 0 1px #444444;
				max-height: 150px;

				&.warning {
					background-color: rgb(255, 230, 230);
				}

				&:focus {
					box-shadow: 0 0 0 2px #444444;
				}

				&.input-attention {
					outline-offset: 0px;
					outline-style: solid;
					outline-width: 5px;
					outline-color: red;
				}

				// &::after {
				// 	content: '';
				// 	display: block;
				// 	position: absolute;
				// 	top: 0;
				// 	left: 0;
				// 	bottom: 0;
				// 	right: 0;
				// 	border-width: 1px;
				// 	border-style: solid;
				// 	border-color: #444444;
				// 	border-radius: 3px;
				// }

				// &:focus {
				// 	&::after {
				// 		border-width: 2px;
				// 	}
				// }

				@media (min-width: $breakpoint-laptop) {
					width: 100%;
					max-height: 240px;
					min-height: 100%;
				}

				&.sub-input {
					margin-top: 10px;

					@media (min-width: $breakpoint-laptop) {
						margin-top: inherit;
					}
				}

				&.label {
					border-width: 0;
					border-style: none;
					box-shadow: none;
					word-break: break-all;
					padding: 0;
				}

				&[type='number']::-webkit-outer-spin-button,
				&[type='number']::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
				&[type='number'] {
					-moz-appearance: textfield;
				}

				&.zone-product-input-info {
					font-size: 11px;
					height: auto;
					overflow: hidden;
					min-height: 30px;
					padding: 10px 8px 8px 0.3rem;

					@media (min-width: $breakpoint-tablet) {
						font-size: 13px;
						padding: 8px;
					}
				}
			}

			.zone-product-entry {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: auto;
				align-self: auto;
				position: relative;
				word-wrap: break-word;

				background-color: $background-color;
				margin: 0.25rem;
				width: calc(100% - 0.5rem);

				&.zone-product-label-container {
					padding: 0.1rem;
				}
				&.zone-product-footer {
					padding: 0.4rem;
				}

				display: flex;
				align-items: center;

				& > label {
					width: 60px;
					flex: 0 0 60px;
				}

				@media (min-width: $breakpoint-tablet) {
					display: block;
				}

				@media (min-width: $breakpoint-laptop) {
					position: unset;
					width: calc(100% - 0.8rem);
					margin: 0 0.4rem;
					text-align: left;
				}

				&.drag {
					width: calc(25% - 0.5rem);

					@media (min-width: $breakpoint-laptop) {
						width: 2%;
					}
				}
				&.edit-button {
					width: calc(100% - 0.5rem);

					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: wrap;
					& > * {
						margin: 0 5px;
					}

					@media (min-width: $breakpoint-tablet) {
						display: block;
					}

					@media (min-width: $breakpoint-laptop) {
						width: calc(6% - 0.8rem);
					}
				}
				&.small {
					width: calc(50% - 0.5rem);

					@media (min-width: $breakpoint-laptop) {
						width: calc(6% - 0.8rem);
					}
				}
				&.medium {
					width: calc(50% - 0.5rem);

					@media (min-width: $breakpoint-laptop) {
						width: calc(10% - 0.8rem);
					}
				}
				&.medium-large {
					width: calc(100% - 0.5rem);

					@media (min-width: $breakpoint-laptop) {
						width: calc(14% - 0.8rem);
					}
				}
				&.large {
					width: calc(100% - 0.5rem);

					@media (min-width: $breakpoint-laptop) {
						width: calc(40% - 0.8rem);
					}
				}
				&.custom {
					width: calc(100% - 0.5rem);

					@media (min-width: $breakpoint-laptop) {
						width: calc(34% - 0.8rem);
					}

					.zone-product-input {
						overflow: auto;
					}
				}
				&.full {
					width: calc(100% - 0.5rem);

					@media (min-width: $breakpoint-laptop) {
						width: calc(54% - 0.8rem);
					}
				}
				&.grow {
					flex-grow: 1;
					flex-shrink: 1;
					width: auto;
				}

				// &:focus {
				//     outline: none;
				// }

				.zone-product-edit-custom {
					.edit-custom-icon {
						margin: auto;
						width: 2.5em;
						height: 2.5em;
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					svg {
						width: 1.5em;
						height: 1.5em;
					}
				}

				.zone-product-entry-label {
					// width: 100%;
					text-align: left;
					font-weight: 600;
					padding-right: 0.5rem;

					word-wrap: break-word;
					@media (min-width: $breakpoint-laptop) {
						display: none;
					}
				}

				.zone-product-extras {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-content: center;
					padding-top: 3px;

					font-size: 0.85em;

					.product-extras-option {
						// box-shadow: 0em 0em 0.3em 0.1em $shadow-sidebar-color;
						cursor: pointer;
						margin: 0px 5px;
						padding: 5px 10px;
						// border-radius: 5px;

						display: flex;
						align-items: center;

						.copy-svg {
							width: 0.8em;
							height: 0.8em;
							align-self: flex-start;
						}

						// transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

						// &:hover {
						// 	color: #fff;
						// 	fill: #fff;
						// 	background-color: $color-blue;
						// 	box-shadow: 0em 0em 0.3em 0.1em $shadow-color;
						// }
					}
				}

				.zone-product-search {
					position: absolute;
					// top: 100%;
					top: 38px;
					left: 0;
					padding: 0.25rem;
					border-radius: 0.5rem;
					border-style: solid;
					border-width: 2px;
					border-color: $color-blue;
					width: calc(100% - 0.25rem);
					// Min === use css min, min === use SASS min
					max-height: Min(24rem, 75vh);
					overflow: auto;
					background: $background-color;
					margin: 0.125rem 0.25rem;
					z-index: 750;

					.zone-product-search-item {
						padding: 0.125rem;
						margin-top: 0.25rem;
						cursor: pointer;
						width: 100%;
						display: flex;
						border-radius: 0.25rem;

						&.warning {
							background: rgb(255, 210, 210);
						}

						&:hover,
						&:focus {
							outline: none;
							background: rgb(255, 175, 175);
						}

						.product-search-cod {
							width: 15%;

							padding: 2px 2px 2px 6px;
							word-wrap: normal;
							border-style: solid;
							border-width: 0px;
							border-left-width: 1px;
						}
						.product-search-sku {
							// font-size: 55%;
							line-height: 1rem;
							width: 25%;

							padding: 2px;
							word-wrap: break-word;
							border-style: solid;
							border-width: 0px;
							border-right-width: 1px;
							// display: none;

							// @media (min-width: $breakpoint-laptop) {
							// 	display: block;
							// }

							// @media (min-width: $breakpoint-lg) {
							// 	font-size: 65%;
							// }

							// @media (min-width: $breakpoint-xl) {
							// 	font-size: 70%;
							// }
						}

						.product-search-label {
							width: 60%;
							padding: 2px 2px 2px 6px;
							hyphens: auto;

							// @media (min-width: $breakpoint-laptop) {
							// 	width: 80%;
							// }
						}
					}
				}
			}
		}
	}
}
