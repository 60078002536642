$border-color: rgb(228, 240, 248);

.custom-table {
	margin-top: 25px;
	width: 100%;
	text-align: left;

	tr {
		td,
		th {
			padding: 7px;
			border: solid 1px $border-color;

			&.no-pad {
				padding: 0px;
			}
		}

		// .cell-subtable {
		//     width: 100%;
		//     padding: 10px;
		// }
	}
}
