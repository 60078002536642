@import '../abstracts/variables.scss';

.suggestion-modal {
	.confirm-button {
		//
		margin: 10px;
		padding: 10px;
		border-radius: 7px;
		cursor: pointer;
		user-select: none;

		background-color: $background-color;
		color: #000;
		border: $color-blue 1px solid;

		transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;
		box-shadow: 0 3px 10px rgba(87, 87, 87, 0.2);

		&:hover {
			// @extend .shadow;
			box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
				0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

			background-color: rgb(55, 141, 253);
			fill: #fff;
			color: #fff;
		}
	}

	.suggestion-list {
		display: grid;
		grid-template-columns: 1fr;
		gap: 10px;

		@media (min-width: $breakpoint-tablet) {
			grid-template-columns: 1fr 1fr;
		}

		.suggestion-row {
			display: grid;
			justify-items: start;
			grid-template-columns: 75px 1fr;
			padding: 7px;
			border-radius: 5px;
			border: solid 1px rgba(0, 0, 0, 0.333);
			background-color: rgba(126, 126, 126, 0.333);

			cursor: pointer;

			div {
				margin: 0 7px;
			}

			background-color: rgb(255, 210, 210);
			color: #000;
			border: $color-blue 1px solid;

			transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;
			box-shadow: 0 3px 10px rgba(87, 87, 87, 0.2);

			&.selected {
				border: solid 1px rgb(0, 38, 165);
				background-color: $background-color;
				// background-color: hsl(214, 95%, 45%);

				// fill: #fff;
				// color: #fff;
			}

			&:hover {
				// @extend .shadow;
				box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
					0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

				background-color: rgb(55, 141, 253);
				fill: #fff;
				color: #fff;

				&.selected {
					background-color: rgb(255, 210, 210);
					fill: #000;
					color: #000;
				}
			}
		}
	}
}
