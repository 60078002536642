$underline-size: 2px;
$border-color: rgb(228, 240, 248);
$background-color: #fff;
$color-blue: rgb(55, 141, 253);
$shadow-color: rgba(0, 128, 255, 0.2);

$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1080px;
$breakpoint-desktop: 1400px;
$breakpoint-xl: 1680px;

.new-configurator {
	// position: relative;
	.configurator-head-options {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 2em;

		.configurator-length {
			text-align: left;
			width: 50%;

			.length-title {
				margin-bottom: 0.25em;
			}

			.length-input {
				width: 100%;
				background-color: #f5f5f5;
				padding: 5px;
				border: none;
				border-bottom: solid 1px black;
			}
		}

		.advanced-toggle {
			margin-left: auto;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.configurator-toggle-label {
				text-align: center;
				margin-bottom: 8px;
			}
			.configurator-toggle-container {
				display: flex;
				align-items: center;

				.configurator-toggle {
					position: relative;
					cursor: pointer;
					width: 90px;
					height: 36px;
					background-color: rgb(255, 176, 176);
					border-radius: 20px;

					transition: background-color 350ms ease-in-out;

					.configurator-toggle-index {
						position: absolute;
						width: 28px;
						height: 28px;
						border-radius: 100%;
						top: 4px;
						left: 5px;

						transition: transform 350ms ease-in-out, background-color 350ms ease-in-out;

						background-color: rgb(235, 38, 38);
					}

					&.checked {
						background-color: rgb(174, 212, 255);
						.configurator-toggle-index {
							transform: translateX(50px);
							background-color: rgb(11, 125, 255);
							// left: calc(100% - 35px);
						}
					}
				}

				.configurator-toggle-off {
					margin-right: 5px;
					color: rgb(235, 38, 38);
				}
				.configurator-toggle-on {
					margin-left: 5px;
					color: rgb(11, 125, 255);
				}
			}
		}
	}

	.main-body {
		// overflow: auto;
		max-height: 100%;
		// max-width: 1600px;

		display: flex;
		justify-content: center;

		.body-preview {
			min-width: 400px;

			align-self: center;
			text-align: left;
			position: sticky;
			top: 0px;
			align-self: flex-start;
			// top: 50%;
			// transform: translateY(-50%);
			width: 25%;
			margin-left: 5px;
			border-left: $border-color 2px solid;

			max-height: 80vh;
			overflow: auto;

			@media only screen and (max-width: $breakpoint-laptop) {
				padding: 10px 20px 10px 20px;
				width: 100%;
				border-left: none;
				border-top: $border-color 2px solid;

				max-height: unset;
				overflow: unset;
			}

			.price-preview {
				padding: 15px 5px 5px 20px;
			}

			.configurator-checkout-button {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 10px 20px;
				padding: 15px;
				border-radius: 10px;
				cursor: pointer;
				user-select: none;

				background-color: $background-color;
				color: #000;
				border: $color-blue 1px solid;

				transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;
				box-shadow: 0 3px 10px rgba(87, 87, 87, 0.2);

				&:hover {
					// @extend .shadow;
					box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
						0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

					background-color: rgb(55, 141, 253);
					fill: #fff;
					color: #fff;
				}
			}

			.side-preview-option {
				margin: 7px 0px;
				display: grid;
				grid-template-columns: 2.5em 1fr;
				align-items: center;
				word-break: break-word;
				text-align: left;

				&.space-top {
					margin-top: 1.75em;
				}

				.side-preview-icon {
					margin-right: 10px;
				}

				.side-preview-name {
					font-weight: bold;
					margin-right: 5px;
				}
				.side-preview-info {
					grid-column: span 2;
				}
			}

			.steps-preview {
				margin-left: 0;
			}

			.steps-preview,
			.info-preview {
				padding: 15px 5px 15px 20px;
				border-bottom: $border-color 2px solid;
			}

			.step-preview-image {
				width: 80%;
				margin: 0 10%;
				img {
					width: 100%;
				}
			}
		}

		.body-content {
			max-width: 1200px;
			padding: 10px 25px;
			flex: 1 1 auto;

			.step {
				text-align: left;
				margin: 2em 0px;

				.step-title {
					display: flex;
					font-size: 1.5em;
					padding-bottom: 0.5em;
					margin: 0.5em 0px;
					border-bottom: solid 1px rgb(20, 20, 20);

					&.collapsable {
						cursor: pointer;
					}

					.collapse-toggle {
						margin-right: 0.25em;
						transform: rotateZ(90deg);

						transition: transform 350ms ease;
						&.collapsed {
							transform: rotateZ(0deg);
						}
					}
				}

				.step-notes {
					width: 100%;
					min-height: 150px;
					border-radius: 5px;
					outline: none;
					padding: 10px;
					border: rgb(20, 20, 20) 2px solid;
					font-size: 16px;
					line-height: 20px;
					resize: vertical;
				}

				.step-filter {
					position: relative;

					input {
						width: 100%;
						background-color: #f5f5f5;
						padding: 5px;
						border: none;
						border-bottom: solid 1px black;
					}

					.step-filter-list {
						position: absolute;
						left: 0;
						top: 100%;
						width: 100%;
						max-height: 400px;
						overflow: auto;

						border-radius: 0px 0px 10px 10px;
						border: solid 1px black;
						border-top: none;
						background-color: #fff;

						&.hidden {
							visibility: hidden;
							pointer-events: none;
						}

						.step-filter-item {
							cursor: pointer;
							padding: 5px;
							background-color: #fff;

							&:hover {
								background-color: #d1d1d1;
							}

							&:last-child {
								border-radius: 0px 0px 10px 10px;
							}
						}
					}
				}

				.step-text-input-container {
					position: relative;
					display: flex;
					margin-top: 28px;

					.step-text-input {
						padding: 8px;
						outline: none;
						border: none;

						// text-align: center;
						flex: 1 1 auto;
						width: 100%;
						outline: rgb(31, 31, 31) 1px solid;
						background-color: #f0f0f0;
					}
				}

				.step-items {
					display: flex;
					flex-wrap: wrap;
					max-height: 800px;
					overflow: auto;

					&.collapsed {
						//
						max-height: 0;
					}

					.step-item {
						margin: 0.5em 0.75em;
						padding: 0.5em;
						cursor: pointer;

						&.option-item {
							border-bottom: solid $underline-size rgba(0, 0, 0, 0);

							transition: border 350ms ease;
							&:hover {
								border-bottom: solid $underline-size black;
							}

							&.selected {
								border-bottom: solid $underline-size rgb(0, 0, 230);
							}
						}

						&.strip-item {
							width: 100%;
							border: solid 2px transparent;
							transition: border 350ms ease;

							.strip-details-info {
								display: flex;
								align-items: center;

								.strip-details-colors {
									padding-bottom: 4px;
									// margin-right: 10px;
									margin-right: auto;
								}
								.strip-price {
									padding-bottom: 8px;
									font-size: 40px;
									color: lighten($color-blue, 10%);

									.strip-option-price-unit {
										font-size: 22px;
									}
								}
								.strip-text-info {
									padding-bottom: 2px;
									margin-right: 10px;

									// border-bottom: solid $underline-size rgba(0, 0, 0, 0);
									transition: border 350ms ease;
									span {
										&:not(&:last-child) {
											margin-right: 25px;
										}

										&.hide-small {
											@media only screen and (max-width: $breakpoint-desktop) {
												display: none;
											}
										}
									}
								}
							}

							&:hover {
								border: solid $underline-size black;
								// .strip-details-info {
								// 	.strip-text-info {
								// 		border-bottom: solid $underline-size black;
								// 	}
								// }
							}
							&.selected {
								border: solid $underline-size rgb(0, 0, 230);
								// .strip-details-info {
								// 	.strip-text-info {
								// 		border-bottom: solid $underline-size rgb(0, 0, 230);
								// 	}
								// }
							}
						}

						&.profile-item {
							width: 240px;
							text-align: center;

							.profile-image {
								margin-bottom: 0.5em;
								img {
									max-width: 100%;
								}
							}

							.profile-cod,
							.profile-name,
							.profile-description {
								display: block;
								margin: 5px 0px;
							}

							.profile-name {
								font-weight: 600;
							}
							.profile-description {
								font-size: 0.85em;
							}

							// padding-bottom: $underline-size;
							border: solid $underline-size rgba(0, 0, 0, 0);
							transition: border 350ms ease;

							&:hover {
								border: solid $underline-size black;
							}
							&.selected {
								border: solid $underline-size rgb(0, 0, 230);
							}
						}
					}
				}
			}
		}
	}
}
